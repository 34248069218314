/**
* @author 贝才[beica1@outook.com]
* @date 2021/3/19
* @description
*   ExchangeRate.vue of WeTrade
*/
<template>
  <div>
    <t :path="kind === 'out' ? 'withdrawal_5' : 'deposit_4'">Equal to {INR}</t>
    {{reversed ? 'USD' : rate.unit}}
    <money :v="currency" class="c-primary f-medium" #="{value}">
      {{value}}
    </money>
  </div>
</template>

<script lang="ts">
import { useRate } from '@/modules/app/app'
import { mul } from 'essential/tools/math'
import { computed, defineComponent, watchEffect } from 'vue'

export default defineComponent({
  name: 'ExchangeRate',
  props: {
    factor: {
      type: [Number, String],
      default: 1,
    },
    kind: String,
    type: Number,
    reversed: Boolean,
  },
  setup (props, ctx) {
    const isWithdrawal = props.kind === 'out'

    const { rate, refresh } = useRate(props.kind === 'out')
    const currency = computed(() => mul(props.reversed ? rate.value.rate : rate.value.value, props.factor))

    // @todo 出入金拆分
    if (!isWithdrawal) refresh()
    else {
      watchEffect(() => {
        if (props.type) {
          refresh({
            type: props.type,
          })
        }
      })
    }

    watchEffect(() => {
      if (rate.value) {
        ctx.emit('change', rate.value)
      }
    })

    return {
      rate,
      currency,
    }
  },
})
</script>
