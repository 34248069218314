/**
* @author zjc[beica1@outook.com]
* @date 2021/12/14 15:19
* @description
*   Channel.common.vue of WeTrade
*/
<template>
<t as="p" class="f-bold" path="deposit_3">
  Deposit Amount (US Dollar)
</t>
<HoldOn :content="options" :height="160" v-if="channels&&channels.length>0">
  <Matrix :list="options" #="{ item }" class="mt-16">
    <ChoiceButton
      class="p-r df-col"
      :class="{active: item === amount, 'disable': (item<channels[selectIndex].minAmount||item>channels[selectIndex].maxAmount)}"
      @click="changeDepositAmount(item)"
    >
      <money :v="item" style="line-height: 32px;"/>
      <!-- <money :v="item" #="{value}">
        <span class="point" v-if="duringEvent">{{ value * 100 }}</span>
      </money> -->
      <span class="point" v-if="duringEvent">{{ calcPoint(item) }}</span>
    </ChoiceButton>
  </Matrix>
</HoldOn>
<ExchangeRate class="f-sm c-tip" :factor="amount" />

<div class="wedge mt-8 mb-16" style="min-height: 24px">
  <div v-if="disabled"
    class="d-f p-8 br-lg"
    style="background: var(--color-asst-light);">
    <Pic height="16" width="16" src="trade/description2" class="mr-8" />
    <t path="deposit_31"
      :args="{min: '$' + channels[selectIndex].minAmount/format.moneyFactor}"
      class="c-asst f-md" style="line-height: 16px;"
      v-if="amount<channels[selectIndex].minAmount">
      Minimum deposit amount for current method is
       <money :v="channels[selectIndex].minAmount" />
      . Please choose another deposit method.
    </t>
    <t path="deposit_32"
      :args="{min: '$' + channels[selectIndex].maxAmount/format.moneyFactor}"
      class="c-asst f-md" style="line-height: 16px;"
      v-if="amount>channels[selectIndex].maxAmount">
      Maximum deposit amount for current method is
       <money :v="channels[selectIndex].maxAmount" />
      . Please choose another deposit method.
    </t>
  </div>
</div>
<div v-if="channel">
  <t as="p" path="deposit_15" class="f-bold"></t>
  <TheChannels :channels="channels" v-model:channel="channel" value-field="code" @change="channelChange"
    label-field="channel" />
  <div class="wedge" style="height: 32px"></div>
</div>
<Button class="block f-bold f-lg br-lg" :class="{primary: !disabled}" @click="commit(amount)" :progress="progress">
  <t path="deposit_5" #="{td}" custom>{{td || 'Pay now'}}</t>
</Button>
<div class="mt-16 f-md c-title" style="line-height: 20px;">
  <t as="div" path="deposit_6" multiline #="{td}" custom>
    <p v-for="(p, index) in td" :key="index">{{p}}</p>
  </t>
</div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue'
import ChoiceButton from '@/components/ChoiceButton.vue'
import { useDeposit } from '@/modules/in/in'
import { DepositChannel, readDepositChoices } from '@/modules/in/in.api'
import TheChannels from '@/modules/out/components/TheChannels.vue'
import HoldOn from '@/provider/HoldOn.vue'
import ExchangeRate from '@/views/ExchangeRate.vue'
import Matrix from 'common/Matrix.vue'
import Pic from '@/components/Pic.vue'
import { defineComponent, shallowRef, watchEffect } from 'vue'
import { format } from '@/config'
import { useRoute } from 'vue-router'

import { liveDepositPointList } from '@/pages/liveForQuickPlay/liveForQuickPlay.api'
import * as R from 'ramda'

export default defineComponent({
  name: 'ChannelForCommon',
  components: {
    TheChannels,
    ChoiceButton,
    ExchangeRate,
    HoldOn,
    Button,
    Matrix,
    Pic,
  },
  props: {
    channels: {
      type: Array,
      required: true,
    },
    customRequest: Function,
  },
  setup (props) {
    const amount = shallowRef('0')
    const [commit, progress] = useDeposit()
    const channel = shallowRef<string>('')
    const options = shallowRef()
    const selectIndex = shallowRef(0)
    const disabled = shallowRef(false)

    const route = useRoute()

    const pointList = shallowRef<{amount: string, points: string}[]>([])

    const duringEvent = shallowRef(false)

    if (route.query.during) {
      duringEvent.value = true
      Promise.all([readDepositChoices(), liveDepositPointList({
        roomId: route.query.roomid,
      })]).then(res => {
        for (const key in res[1].list) {
          pointList.value.push({
            amount: key,
            points: res[1].list[key].toString(),
          })
        }

        options.value = res[0].list
      })
    } else {
      readDepositChoices().then(res => {
        options.value = res.list
      })
    }

    // Promise.all([readDepositChoices(), liveDepositPointList({
    //   roomid: route.query.roomid,
    // })]).then(res => {
    //   for (const key in res[1].list) {
    //     pointList.value.push({
    //       amount: key,
    //       points: res[1].list[key].toString(),
    //     })
    //   }

    //   options.value = res[0].list
    // })

    const calcPoint = (amount: string | number) => {
      const item = R.find(R.propEq('amount', amount))(pointList.value) as {amount: string, points: string}
      if (item) {
        return item.points
      }
    }

    const disableInit = () => {
      const camount = amount.value === '0' ? Number((options.value ?? ['0'])[0]) : Number(
        amount.value)
      const cmax = (props.channels as DepositChannel[])?.[selectIndex.value].maxAmount
      const cmin = (props.channels as DepositChannel[])?.[selectIndex.value].minAmount

      if (camount > cmax || camount < cmin) {
        disabled.value = true
      } else {
        disabled.value = false
      }
    }

    watchEffect(() => {
      // 默认选中可选金额
      for (let i = 0; i < options.value?.length; i++) {
        if (+options.value[i] >= +(props.channels as DepositChannel[])?.[selectIndex.value].minAmount) {
          amount.value = options.value[i]
          break
        }
      }
      // amount.value = (options.value ?? ['0'])[0]
    })

    watchEffect(() => {
      channel.value = (props.channels as DepositChannel[])?.[0]?.code
    })

    const changeDepositAmount = (item: string) => {
      amount.value = item
      disableInit()
    }

    const request = (amount: string) => {
      if (disabled.value) {
        return
      }
      const params: Record<string, unknown> = {
        amount,
      }

      if (route.query.roomid) {
        params.roomid = route.query.roomid
      }

      if (channel.value) {
        params.payChannel = channel.value
      }

      if (typeof props.customRequest === 'function') {
        return props.customRequest(params, commit)
      }

      return commit(params)
    }

    const channelChange = (index: number) => {
      selectIndex.value = index
      disableInit()
    }

    return {
      channel,
      options,
      amount,
      progress,
      selectIndex,
      disabled,
      commit: request,
      changeDepositAmount,
      channelChange,
      format,
      pointList,
      calcPoint,
      duringEvent,
    }
  },
})
</script>

<style scoped lang="scss">
.disable{
  background: #DDE4EB;
  color: var(--color-tip);
  &.active{
    border-color: var(--color-tip);
    color: var(--color-title);
    ::v-deep(.c-i) {
      background: var(--color-tip);
    }
  }
}

::v-deep(.choice) {
  padding: 0;
}
.point{
  background-color: var(--color-asst-light);
  color: var(--color-asst);
  font-size: var(--font-xs);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  line-height: 16px;
}
</style>
